<template>
  <v-container class="py-3 px-0 px-md-3">
    <v-toolbar flat class="d-flex flex-row-reverse pb-5" color="rgba(0,0,0,0)">
      <Exportar excel :filtro="filtro" relatorioExcel="CarregamentoConcluido"></Exportar>
    </v-toolbar>
    <FiltroMobile :has-change="pendingSearch" @search="getData">
      <v-col class="col-12 col-md-6 col-lg-2">
        <DatePicker label="Data Início" v-model="filterDataInicial"/>
      </v-col>

      <v-col class="col-12 col-md-6 col-lg-2">
        <DatePicker label="Data Fim" v-model="filterDataFinal"/>
      </v-col>

      <v-col class="col-12 col-md-6 col-lg-4">
        <EmpresasSelect v-model="filterSvis" svi></EmpresasSelect>
      </v-col>

      <v-col class="col-12 col-md-6 col-lg-4">
        <ProducoesSelect v-model="filterProducao" :empresa="filterSvis" mostra-todos-label svi return-object></ProducoesSelect>
      </v-col>

      <v-col class="col-12 col-md-8 col-lg-6">
        <v-combobox
            dense
            outlined
            hide-no-data
            persistent-placeholder
            label="Cliente"
            item-text="nome"
            item-value="id"
            v-model="filterCliente"
            :items="clienteOptions"
            :loading="clienteLoading"
            @keydown="clienteSearch"
        >
        </v-combobox>
      </v-col>

      <v-col class="col-12 col-md-4 col-lg-4">
        <v-text-field
            dense
            outlined
            label="Nota Fiscal"
            append-icon="mdi-magnify"
            v-model="filterNotaFiscal"
        />
      </v-col>
    </FiltroMobile>
    <v-container class="v-card v-sheet theme--light p-0 p-md-3">
      <!-- Dados -->
      <v-row>
        <!-- data-table -->
        <v-col class="px-0 px-md-3">
          <v-data-table
              dense
              sort-by=""
              group-by="filial"
              fixed-header
              height="90vh"
              disable-pagination
              :hide-default-footer="true"
              show-expand
              :expanded.sync="dataExpanded"
              :headers="headers"
              :items="data"
              item-key="row-key"
              :loading-text="$dataTableConfig.getLoadText()"
              :loading="loading"
              @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
              :mobile-breakpoint='NaN'
          >

            <template v-slot:progress>
              <v-progress-linear
                  absolute
                  indeterminate
                  color="green"
              ></v-progress-linear>
            </template>

            <template v-slot:[`item.dataCarregamento`]="{item}">
              <span class="d-none d-lg-block">{{ $stringFormat.dateISOToBRL(item.dataCarregamento) }}</span>
              <span class="d-lg-none">{{ $stringFormat.dateISOToBRLMobile(item.dataCarregamento) }}</span>
            </template>
            <template v-slot:[`item.cliente`]="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="text-short mx-auto">{{ item.cliente }}</span>
                </template>
                <span class="">{{ item.cliente }}</span>
              </v-tooltip>

            </template>
            <template v-slot:[`item.qtde`]="{item}">
              <span>{{ $stringFormat.formatM2(item.qtde) }}</span>
            </template>

            <template v-slot:[`item.vrUnit`]="{item}">
              <span>{{ $stringFormat.formatMoney(item.vrUnit) }}</span>
            </template>

            <template v-slot:[`item.vrProd`]="{item}">
              <span>{{ $stringFormat.formatMoney(item.vrProd) }}</span>
            </template>

            <template v-slot:[`item.vrFrete`]="{item}">
              <span>{{ $stringFormat.formatMoney(item.vrFrete) }}</span>
            </template>

            <template v-slot:[`item.vrICMS`]="{item}">
              <span>{{ $stringFormat.formatMoney(item.vrICMS) }}</span>
            </template>

            <template v-slot:[`item.vrRolo`]="{item}">
              <span>{{ $stringFormat.formatMoney(item.vrRolo) }}</span>
            </template>

            <template v-slot:[`item.vrBonif`]="{item}">
              <span>{{ $stringFormat.formatMoney(item.vrBonif) }}</span>
            </template>

            <template v-slot:[`item.vrTotal`]="{item}">
              <span>{{ $stringFormat.formatMoney(item.vrTotal) }}</span>
            </template>

            <template v-slot:[`group.header`]="{group, items, isOpen, toggle}">
              <td colspan="16">
                <v-icon @click="toggle"
                >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                </v-icon>
                {{ group }} ({{ items.length }})
              </td>
            </template>

            <template v-slot:[`group.summary`]="{group}">
              <th class="bg-white" colspan="5">TOTAL DE {{ group }}</th>
              <th class="bg-white text-right" v-for="(sum, index) in groupSummary(group)"
                  :key="`total-grupo-${group}-${index}`">
                <span>{{ index === 0 ? $stringFormat.formatM2(sum) : $stringFormat.formatMoney(sum) }}</span>
              </th>
              <th class="bg-white" colspan="3">&nbsp;</th>
            </template>

            <template v-slot:[`body.append`]="" v-if="rawData.length > 0">
              <tr class="v-row-group__summary">
                <th colspan="5">TOTAL GERAL</th>
                <th class="text-right" v-for="(sum, index) in groupSummary('todos')"
                    :key="`total-grupo-${group}-${index}`">
                  <span>{{ index === 0 ? $stringFormat.formatM2(sum) : $stringFormat.formatMoney(sum) }}</span>
                </th>
                <th colspan="3">&nbsp;</th>
              </tr>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <div class="py-2 align-top">
                  <span class="d-flex"> <strong>Total de Descontos (R$):</strong>&nbsp; {{ item.vrDes }}</span>
                  <span class="d-flex"> <strong>Retirada:</strong>&nbsp; {{ item.retira }}</span>
                  <span class="d-flex"> <strong>Motorista:</strong>&nbsp; {{ item.motorista }}</span>
                  <span class="d-flex" v-if="!!item.telefone_motorista"> <strong>Telefone Motorista:</strong>&nbsp; {{ $stringFormat.formatTelefone(item.telefone_motorista) }}</span>
                  <span class="d-flex" v-if="!!item.documento_motorista"> <strong>Documento Motorista:</strong>&nbsp; {{ $stringFormat.formatCpf(item.documento_motorista) }}</span>
                  <span class="d-flex"> <strong>Vendedor(a):</strong>&nbsp; {{ item.vendedor }}</span>
                  <span class="d-flex"> <strong>Pedido criado em:</strong>&nbsp; {{ item.data_criacao }}</span>
                  <span class="d-flex"> <strong>Última alteração em:</strong>&nbsp; {{ item.data_atualizacao }}</span>
                  <span class="d-flex" v-if="!!item.observacao"><strong>Observação:</strong>&nbsp; {{ item.observacao }}</span>
                </div>
                <div class="w-auto max-w-80vw mb-2">
                  <v-data-table
                      class="w-auto"
                      item-key="seqipo"
                      dense
                      :headers="headersProdutos"
                      :items="item.produtos"
                      :items-per-page="10"
                      :mobile-breakpoint="0"
                  >
                    <template v-slot:[`item.produto`]="{ item }">
                      <span class="mt-2 mb-2 d-flex flex-column">
                        {{ item.produto }}
                      </span>
                    </template>
                    <template v-slot:[`item.qtde`]="{ item }">
                      <span class="mt-2 mb-2 d-flex flex-column">
                        {{ $stringFormat.formatM2(item.qtde)}}
                      </span>
                    </template>
                    <template v-slot:[`item.vrUnit`]="{ item }">
                      <span class="mt-2 mb-2 d-flex flex-column">
                        {{ $stringFormat.formatMoney(item.vrUnit) }}
                      </span>
                    </template>
                    <template v-slot:[`item.vrProd`]="{ item }">
                      <span class="mt-2 mb-2 d-flex flex-column">
                        {{ $stringFormat.formatMoney(item.vrProd) }}
                      </span>
                    </template>
                    <template v-slot:[`item.vrFrete`]="{ item }">
                      <span class="mt-2 mb-2 d-flex flex-column">
                        {{ $stringFormat.formatMoney(item.vrFrete) }}
                      </span>
                    </template>
                    <template v-slot:[`item.vrICMS`]="{ item }">
                      <span class="mt-2 mb-2 d-flex flex-column">
                        {{ $stringFormat.formatMoney(item.vrICMS) }}
                      </span>
                    </template>
                    <template v-slot:[`item.vrRolo`]="{ item }">
                      <span class="mt-2 mb-2 d-flex flex-column">
                        {{ $stringFormat.formatMoney(item.vrRolo) }}
                      </span>
                    </template>
                    <template v-slot:[`item.vrBonif`]="{ item }">
                      <span class="mt-2 mb-2 d-flex flex-column">
                        {{ $stringFormat.formatMoney(item.vrBonif) }}
                      </span>
                    </template>
                    <template v-slot:[`item.vrTotal`]="{ item }">
                      <span class="mt-2 mb-2 d-flex flex-column">
                        {{ $stringFormat.formatMoney(item.vrTotal) }}
                      </span>
                    </template>
                  </v-data-table>
                </div>
              </td>
            </template>
            <template
                v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }"
            >
              <v-icon
                  v-if="item.produtos"
                  :class="[
                    'v-data-table__expand-icon',
                    { 'v-data-table__expand-icon--active': isExpanded }
                  ]"
                  @click.stop="expand(!isExpanded)"
              >
                $expand
              </v-icon>
            </template>
            <template v-slot:no-data>
              <v-spacer/>
              <div v-if="dataError">
                <v-row class="justify-center mt-1">
                  <v-col class="col-lg-6">
                    <custom-alert :text="errorMessage"/>
                  </v-col>
                </v-row>
              </div>
               <div v-else-if="loading">
                  <v-row style="margin: -16px">
                    <v-col class="col-lg-12 p-0">
                      <v-progress-linear absolute indeterminate color="green" />
                    </v-col>
                  </v-row>
                </div>

                <div v-else-if="filterSvis == '' || filterProducao == ''">
                <v-row class="justify-center my-1">
                  <v-col class="col-lg-6">
                    <h6>Selecione Empresa e Produção</h6>
                  </v-col>
                </v-row>
              </div>
            </template>
          </v-data-table>
        </v-col>
        <!-- fim data-table -->
      </v-row>
    </v-container>
  </v-container>
</template>

<style scoped>

.col-btn {
  max-width: max-content;
}

.v-btn--height-fix {
  height: 40px !important;
}
</style>

<script>
import { mapGetters } from 'vuex'

import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  data: function () {
    return {
      // General
      pendingSearch: false,
      loading: false,
      clienteLoading: false,
      clienteOptions: [],

      // Data
      dataError: false,
      errorMessage: '',
      rawData: [],
      data: [],
      dataExpanded: [],
      producoes: [],

      // Filters
      filterDataInicial: '',
      filterDataFinal: '',
      filterSvis: '',
      filterProducao: { id: '', producao_id: '', empresa_id: '' },
      filterCliente: '',
      filterNotaFiscal: '',
    }
  },

  computed: {
    ...mapGetters(['currentUser']),

    headers () {
      return [
        { text: '', value: 'data-table-expand', class: 'custom-table--header' },
        { text: 'Dt. Carreg.', value: 'dataCarregamento', class: 'custom-table--header' },
        { text: 'Pedido', value: 'pedido', class: 'custom-table--header' },
        { text: 'NF', value: 'nf', class: 'custom-table--header' },
        { text: 'Cliente', value: 'cliente', class: 'custom-table--header' },
        { text: 'Qtde (m²)', value: 'qtde', align: 'end', class: 'custom-table--header' },
        { text: 'Vr. Unit. (R$)', value: 'vrUnit', width: '10', align: 'end', class: 'custom-table--header' },
        { text: 'Vr. Prod. (R$)', value: 'vrProd', width: '10', align: 'end', class: 'custom-table--header' },
        { text: 'Vr. Frete (R$)', value: 'vrFrete', width: '10', align: 'end', class: 'custom-table--header' },
        { text: 'Vr. ICMS (R$)', value: 'vrICMS', width: '10', align: 'end', class: 'custom-table--header' },
        { text: 'Mx. Rolo', value: 'vrRolo', width: '10', align: 'end', class: 'custom-table--header' },
        { text: 'Vr. Bonif (R$)', value: 'vrBonif', width: '10', align: 'end', class: 'custom-table--header' },
        { text: 'Vr. Total (R$)', value: 'vrTotal', width: '10', align: 'end', class: 'custom-table--header' },
        { text: 'Cidade', value: 'cidade', width: '10', align: 'end', class: 'custom-table--header' },
        { text: 'Estado', value: 'estado', width: '10', align: 'end', class: 'custom-table--header' },
        { text: 'Distancia', value: 'distancia', width: '10', align: 'end', class: 'custom-table--header' },
      ]
    },
    headersProdutos () {
      return [
        { text: 'Produto', value: 'produto', class: 'custom-table--header' },
        { text: 'Qtde (m²)', value: 'qtde', align: 'end', class: 'custom-table--header' },
        { text: 'Vr. Unit. (R$)', value: 'vrUnit', width: '10', align: 'end', class: 'custom-table--header' },
        { text: 'Vr. Prod. (R$)', value: 'vrProd', width: '10', align: 'end', class: 'custom-table--header' },
        { text: 'Vr. Frete (R$)', value: 'vrFrete', width: '10', align: 'end', class: 'custom-table--header' },
        { text: 'Vr. ICMS (R$)', value: 'vrICMS', width: '10', align: 'end', class: 'custom-table--header' },
        { text: 'Mx. Rolo', value: 'vrRolo', width: '10', align: 'end', class: 'custom-table--header' },
        { text: 'Vr. Bonif (R$)', value: 'vrBonif', width: '10', align: 'end', class: 'custom-table--header' },
        { text: 'Vr. Total (R$)', value: 'vrTotal', width: '10', align: 'end', class: 'custom-table--header' },
      ]
    },
    filtro () {
      return {
        data_inicial: this.filterDataInicial,
        data_final: this.filterDataFinal,
        empresa: this.filterProducao && this.filterProducao.empresa_id !== undefined ? this.filterProducao.empresa_id ?? 0 : 0,
        producao: this.filterProducao && this.filterProducao.producao_id !== undefined ? this.filterProducao.producao_id ?? 0 : 0,
        cliente: this.filterCliente.id || 0,
        nota_fiscal: this.filterNotaFiscal || 0,
      }
    },
  },

  watch: {
    filterDataInicial () {
      this.pendingSearch = true
    },
    filterDataFinal () {
      this.pendingSearch = true
    },
    filterSvis () {
      this.pendingSearch = true
    },
    filterCliente () {
      this.pendingSearch = true
    },
    filterNotaFiscal () {
      this.pendingSearch = true
    },
  },

  created () {
    let dataInicioFiltro = this.$date.getISOToday()
    let dataFimFiltro = this.$date.getISOToday()
    let sviFiltro = ''
    let producaoFiltro = ''
    let empresaFiltro = ''
    let filtroDashboard = false

    if (
      this.$route.query.empresa_id &&
      this.$route.query.producao_id &&
      this.$route.query.data_carregamento
    ) {
      filtroDashboard = true
    }

    if (filtroDashboard) {
      const data = this.$route.query.data_carregamento.split('-')
      const dataInicio = new Date(parseInt(data[0]), parseInt(data[1]) - 1, 1)
      const dataFim = new Date(parseInt(data[0]), parseInt(data[1]), 0)
      const producoes = this.$producoes.list('', false)
      const empresaId = parseInt(this.$route.query.empresa_id)
      const producaoId = parseInt(this.$route.query.producao_id)

      const producao = producoes.filter(
        (producao) => producao.id === producaoId && producao.empresa_id === empresaId,
      )

      dataInicioFiltro = this.$stringFormat.dateToISO(dataInicio.toLocaleString())
      dataFimFiltro = this.$stringFormat.dateToISO(dataFim.toLocaleString())
      sviFiltro = producao[0].svi
      empresaFiltro = producao[0].empresa_id
      producaoFiltro = producao[0].id
    }

    this.filterDataInicial = dataInicioFiltro
    this.filterDataFinal = dataFimFiltro
    this.filterSvis = sviFiltro
    this.filterProducao.empresa_id = empresaFiltro
    this.filterProducao.id = empresaFiltro + String(producaoFiltro).padStart(5, '0')
    this.filterProducao.producao_id = producaoFiltro

    this.getData()
  },

  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Agricola', route: 'agricola' },
      { title: 'Carregamentos Concluídos' },
    ])
  },

  methods: {
    getData () {
      this.loading = true
      this.pendingSearch = false
      const params = this.filtro

      ApiService.get('/carregamentos-concluidos', '?' + this.$stringFormat.queryParams(params)).then((res) => {
        const dados = res.data
        this.loading = false
        this.rawData = dados.data
        this.dataError = false

        if (this.rawData.length === 0) {
          this.dataError = true
          this.errorMessage = 'Nenhum registro encontrado'
        }
      }).catch((error) => {
        this.loading = false
        this.dataError = true
        console.error(error)
      }).finally(() => {
        this.processData()
      })
    },

    processData () {
      let processedData = []

      // Agrupa produtos dentro de uma única nota fiscal
      this.rawData.map((pedido) => {
        let pedidoEncontrado

        if ((pedidoEncontrado = processedData.findIndex((item) => item.nf === pedido.NF)) === -1) {
          processedData.push({
            dataCarregamento: pedido.Emissao,
            filial: pedido.Filial,
            pedido: pedido.Pedido,
            nf: pedido.NF,
            cliente: pedido.Cliente,
            titulos: pedido.QtdTit,
            retira: pedido.Retirada,
            motorista: pedido.Motorista,
            telefone_motorista: pedido.FoneMoto,
            documento_motorista: pedido.DocumentoMoto,
            observacao: pedido.Obs,
            vendedor: pedido.Vendedor,
            cidade: pedido.Cidade,
            estado: pedido.Estado,
            distancia: pedido.distancia,
            data_criacao: this.$stringFormat.dateISOToBRL(pedido.DatCri),
            data_atualizacao: this.$stringFormat.dateISOToBRL(pedido.DatAtu),
            qtde: 0,
            vrUnit: 0,
            vrProd: 0,
            vrFrete: 0,
            vrICMS: 0,
            vrRolo: 0,
            vrBonif: 0,
            vrTotal: 0,
            vrDes: 0,
            produtos: [
              {
                produto: pedido.Produto,
                qtde: pedido.Qtde,
                vrUnit: pedido.VrItem,
                vrProd: pedido.VrProd,
                vrFrete: pedido.VrFrete,
                vrICMS: pedido.VrICMS,
                vrRolo: pedido.VrServ,
                vrBonif: pedido.VrBonif,
                vrDes: pedido.VrDes,
                vrTotal: pedido.VrTotal,
              },
            ],
          })
        } else {
          processedData[pedidoEncontrado].produtos.push({
            produto: pedido.Produto,
            qtde: pedido.Qtde,
            vrUnit: pedido.VrItem,
            vrProd: pedido.VrProd,
            vrFrete: pedido.VrFrete,
            vrICMS: pedido.VrICMS,
            vrRolo: pedido.VrServ,
            vrBonif: pedido.VrBonif,
            vrDes: pedido.VrDes,
            vrTotal: pedido.VrTotal,
          })
        }

        return pedido
      })

      // Soma valores de produtos por NF/Pedido
      processedData = processedData.map((pedido) => {
        const totais = pedido.produtos.reduce(
          (accTotais, produto) => {
            accTotais.qtde += parseFloat(produto.qtde)
            accTotais.vrProd += parseFloat(produto.vrProd)
            accTotais.vrFrete += parseFloat(produto.vrFrete)
            accTotais.vrICMS += parseFloat(produto.vrICMS)
            accTotais.vrRolo += parseFloat(produto.vrRolo)
            accTotais.vrBonif += parseFloat(produto.vrBonif)
            accTotais.vrTotal += parseFloat(produto.vrTotal)
            accTotais.vrDes += parseFloat(produto.vrDes)
            return accTotais
          },
          {
            qtde: 0,
            vrUnit: 0,
            vrProd: 0,
            vrFrete: 0,
            vrICMS: 0,
            vrRolo: 0,
            vrBonif: 0,
            vrTotal: 0,
            vrDes: 0,
          },
        )

        totais.vrUnit = totais.vrProd / totais.qtde

        return { ...pedido, ...totais }
      })

      this.data = processedData.map((item, index) => ({ 'row-key': `${index}_${item.pedido}`, ...item }))
    },

    groupSummary (group = null) {
      const sum = this.rawData.reduce((acc, value) => {
        if (group === value.Filial || group === 'todos') {
          acc.qtde += parseFloat(value.Qtde)
          acc.vrProd += parseFloat(value.VrProd)
          acc.vrFrete += parseFloat(value.VrFrete)
          acc.vrICMS += parseFloat(value.VrICMS)
          acc.vrRolo += parseFloat(value.VrServ)
          acc.vrBonif += parseFloat(value.VrBonif)
          acc.vrTotal += parseFloat(value.VrTotal)
        }

        return acc
      }, {
        qtde: 0,
        vrUnit: 0,
        vrProd: 0,
        vrFrete: 0,
        vrICMS: 0,
        vrRolo: 0,
        vrBonif: 0,
        vrTotal: 0,
      })

      sum.vrUnit = sum.vrProd / sum.qtde

      return Object.keys(sum).map((a) => sum[a])
    },

    clienteSearch (event) {
      setTimeout(() => {
        const searchText = event.target.value

        if (searchText === null || searchText.length < 3 || this.clienteLoading) {
          return
        }

        const params = {
          cliente: searchText,
        }

        this.clientLoading = true

        ApiService.get('/sapiens/cliente', '?' + this.$stringFormat.queryParams(params)).then((res) => {
          this.clienteOptions = res.data.data
          this.clienteLoading = false
        }).catch((error) => {
          this.clienteLoading = false
          console.error(error)
        })
      }, 10)
    },
  },
}
</script>
